import React, { Component } from 'react';
import { buildOptions } from '../HelperFunctions';

export default class Select extends Component {

  componentDidMount() {
    const { input: { value, onChange, onBlur }, valueField, placeholder, reset } = this.props
    var formatData = this.handleDataBuild()
    if ((!value || reset) && !(placeholder || placeholder === "")) {
      var val = formatData.length > 0 ? formatData[0][valueField] : ""
      onChange(val)
      onBlur(val)
    }
  }

  handleDataBuild() {
    const { input: { value }, data, textField, valueField, supportAutoComplete } = this.props
    if (supportAutoComplete) {
      let valExist = data.find(item => item[valueField] == value)
      if (valExist) {
        return buildOptions(data, textField, valueField)
      }
      else {
        // handle case if value not exist in our fields
        // (specially exam vide mode change from modern to classic)
        let newData = []
        newData = [{ [valueField]: value }, ...data]
        return buildOptions(newData, textField, valueField)
      }
    }
    return buildOptions(data, textField, valueField)
  }

  render() {
    const {
      props,
      props: { input, disabled, wrapperClasses, label, placeholder, textField, valueField },
      props: { meta: { touched, error, warning } },
    } = this;
    var formattedData = this.handleDataBuild()

    return (
      <div className={`${wrapperClasses} ${disabled && 'disabled'}`}>
        {label && <label className={input.value && 'active'}>{label}</label>}
        <select {...input} className={`custom-select ${props.className} ${touched && error && 'is-invalid'}`}>
          {(placeholder || placeholder === "") && <option value="" selected className="text-muted">{placeholder}</option>}
          {formattedData.map(data =>
            <option key={data[valueField]} value={data[valueField]}>{data[textField]}</option>
          )}
        </select>

        {touched &&
          ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}