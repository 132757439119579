import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const Impression = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/impression/Impression')
)
const Procedure = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/procedure/Procedure')
)
const MedicalHistory = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/diseases/MedicalHistory')
)
const OscularHistory = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/diseases/OscularHistory')
)
const Allergies = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/diseases/Allergies')
)
const Surgery = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/diseases/Surgery')
)
const Medication = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/medication/Medication')
)
const Recommendation = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/recommendation/Recommendation')
)
const Dosage = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/dosage/Dosage')
)
const PrescribingMedication = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formValues/prescribingMedication/PrescribingMedication')
)

const FormTemplate = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/formTemplate/FormTemplate')
);

const ExamViewForm = asyncComponent(() =>
  import('../../../containers/authorised/setting/exam/view/ExamViewForm')
);

const ExamRoutes = (
  <Switch>
    <Route exact path="/settings/exam-form-values/medical-history" component={MedicalHistory} />
    <Route exact path="/settings/exam-form-values/ocular-history" component={OscularHistory} />
    <Route exact path="/settings/exam-form-values/allergies" component={Allergies} />
    <Route exact path="/settings/exam-form-values/surgery" component={Surgery} />
    <Route exact path="/settings/exam-form-values/medication" component={Medication} />
    <Route exact path="/settings/exam-form-values/impression" component={Impression} />
    <Route exact path="/settings/exam-form-values/procedure" component={Procedure} />
    <Route exact path="/settings/exam-form-values/recommendation" component={Recommendation} />
    <Route exact path="/settings/exam-form-values/dosage" component={Dosage} />
    <Route exact path="/settings/exam-form-values/prescibing-medications" component={PrescribingMedication} />
    <Redirect exact from="/settings/exam-form-values" to="/settings/exam-form-values/procedure" />
    <Route exact path="/settings/exam-form-template" component={FormTemplate} />
    <Route exact path="/settings/exam-form-view" component={ExamViewForm} />
    <Redirect exact from="/settings" to="/settings/exam-form-template" />
  </Switch>
);

export default ExamRoutes;