import React from 'react'
import { connect } from 'react-redux'
import InputDropdown from './InputDropdown'
import Select from './Select'

function ConditionalDropdown(props) {
  const { examViewModern } = props
  return examViewModern ? <InputDropdown {...props} /> : <Select {...props} className="pl-3" />;
}

const mapStateToProps = (state) => {
  const { setting: { orgConfig } } = state
  return {
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern'
  }
}

export default connect(mapStateToProps)(ConditionalDropdown)